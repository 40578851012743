export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const LINK_PATH = "/login_to_save";
export const SIGNUP_PATH = "/signup";
export const RESET_PATH = "/reset";
export const VERIFY_PATH = "/verify";
export const EDITOR_PATH = "/editor";
export const PROFILE_PATH = "/settings";
export const SETTINGS_PATH = "/settings";
export const PRICING_PATH = "/pricing";
export const WIZARD_PATH = "/wizard";
export const API_LOGIN_PATH = "/api/login";
export const API_SIGNOUT_PATH = "/api/signout";
export const API_BASE_PATH = process.env.NEXT_PUBLIC_API_URL;
export const PROJECT_PATH = "/projects";
export const COLLABORATION_PATH = "/projects/collaborations";
export const CHARACTER_PATH = "/characters";
export const SCRIPTS_PATH = "/scripts";
export const SERIES_PATH = "/series";
export const ADMIN_PATH = "/admin";
export const ABOUT_ME_PATH = "/about-me";
export const APP_ADMIN_PATH = "/app-admin";
export const TERMS_PATH = "https://shortbread.ai/terms";
export const PRIVACY_PATH = "https://shortbread.ai/privacy";
export const SHORTBREAD_PATH = "https://shortbread.ai";
