import { signInWithPopup } from "firebase/auth";
import posthog from "posthog-js";
import {
  deleteSessionCookie,
  refreshSessionCookie,
} from "../app/actions/authActions";
import { authClient, provider } from "../utils/firebase/firebaseClientConfig";
import { createUser } from "../utils/firebase/userOperations";

export const login = async () => {
  const result = await signInWithPopup(authClient, provider);
  const idToken = await result.user.getIdToken();
  await createUser(result.user.uid, result.user.email || "");
  await refreshSessionCookie(idToken);
  posthog.identify(authClient.currentUser?.uid);
};

export const logout = async () => {
  authClient.signOut();
  await deleteSessionCookie();
};
