"use client";

import { PRIVACY_PATH, TERMS_PATH } from "@/src/constants/route";
import { login } from "@/src/lib/authentication";
import { useRouter } from "next/navigation";
import { useState } from "react";
import Spinner from "../ui/custom/LoadingSpinner";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const router = useRouter();

  const buttonClasses = `w-80 h-16 py-2 px-4 font-semibold border rounded-custom-radius text-center transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0 ${
    termsAccepted
      ? "bg-shortbread-purple text-white hover:text-black border-shortbread-purple hover:bg-white hover:text-shortbread-purple hover:border-shortbread-purple"
      : "bg-gray-400 text-white border-gray-400 cursor-not-allowed"
  }`;

  const handleSignIn = async () => {
    if (termsAccepted) {
      try {
        setLoading(true);
        await login();
        router.push("/projects");
      } catch (err) {
        console.warn(err);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="p-8 rounded-lg shadow-md w-full text-center h-full flex flex-col justify-center items-center">
        <div className="w-10 h-10">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 rounded-lg shadow-md text-center h-full w-full flex flex-col justify-center items-center">
      <h1 className="text-center text-2xl font-bold mb-6 text-white">
        Sign in to your account
      </h1>
      <button
        className={buttonClasses}
        disabled={!termsAccepted}
        onClick={handleSignIn}
      >
        Authenticate with Google
      </button>
      <div className="flex items-center mt-2 gap-2">
        <p className="text-white text-sm md:text-base text-wrap w-60">
          I agree with the{" "}
          <a
            onClick={() => router.push(TERMS_PATH)}
            className="text-bold cursor-pointer"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            onClick={() => router.push(PRIVACY_PATH)}
            className="text-bold cursor-pointer"
          >
            Privacy Policy.
          </a>
        </p>
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
        />
      </div>
    </div>
  );
};

export default LoginForm;
